import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RouteInfo } from './sidebar.metadata';
import { inProgressStatuses, pendingStatuses } from '../data/serviceStatuses';

@Injectable({
    providedIn: 'root'
})
export class AppRoutesService {

    constructor(private authSvc: AuthService) { }

    getRoutes(): RouteInfo[] {
        const canViewInventory = this.authSvc.canViewInventory()
            || this.authSvc.canViewDeliveryOrder()
            || this.authSvc.canManageStores();

        const canViewService = this.authSvc.canViewServiceOrder()
            || this.authSvc.canViewPartReturn()
            || this.authSvc.canApproveServiceOrder()
            || this.authSvc.canConfirmPartReturn();

        const routes = [
            {
                path: '/dashboard', visible: true, title: 'Tổng hợp', icon: 'ft-pie-chart', class: '', isExternalLink: false, submenu: []
            },
            {
                path: '', visible: canViewService, title: 'Báo cáo', icon: 'fa fa-bar-chart', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/reports/service-report-paid', visible: this.authSvc.canViewServiceReport(), title: 'Đã thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/reports/service-report-approved', visible: this.authSvc.canViewServiceReport(), title: 'Đã duyệt thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/reports/service-report-rejected', visible: this.authSvc.canViewServiceReport(), title: 'Không thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/reports/service-part-report', visible: this.authSvc.canViewServicePartReport(), title: 'Báo cáo thay LK', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/reports/inventory-report', visible: this.authSvc.canViewInventoryReport(), title: 'Giao dịch kho', icon: '', class: '', isExternalLink: false, submenu: []
                    }
                ]
            },
            {
                path: '', visible: canViewService, title: 'Bảo hành', icon: 'fa fa-wrench', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/warranty/open', visible: this.authSvc.canViewServiceOrder(), title: 'Chờ tiếp nhận', icon: '', class: '', badge: 'warranty', badgeStatus: { status: pendingStatuses }, isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/in-progress', visible: this.authSvc.canViewServiceOrder(), title: 'Đang xử lý', icon: '', class: '', badge: 'warranty', badgeStatus: { status: inProgressStatuses }, isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/complete', visible: this.authSvc.canViewServiceOrder(), title: 'Hoàn tất', icon: '', class: '', badge: 'warranty', badgeStatus: { status: ['complete'] }, isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/request-information', visible: this.authSvc.canExplain(), title: 'Yêu cầu giải trình', icon: '', class: '', badge: 'warranty', badgeStatus: { status: ['request-information'] }, isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/payment-approve', visible: this.authSvc.canApproveServiceOrder(), title: 'Duyệt thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/billing', visible: this.authSvc.isASPManager(), title: 'Xuất hóa đơn', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/payment-requests', visible: this.authSvc.canApproveServiceOrder(), title: 'Thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/return-parts', visible: this.authSvc.canViewPartReturn(), title: 'Xác linh kiện', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/confirm-return-parts', visible: this.authSvc.canConfirmPartReturn(), title: 'Nhận xác linh kiện', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/part-orders', visible: this.authSvc.canApprovePartOrders(), title: 'Duyệt cấp linh kiện', icon: '', class: '', badge: 'stock', badgeStatus: { status: ['open', 'approved'] }, isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/orders', visible: this.authSvc.canViewServiceOrder(), title: 'Danh sách sửa chữa', icon: '', class: '', isExternalLink: false, submenu: []
                    }
                ]
            },
            {
                path: '', visible: canViewInventory, title: 'Kho hàng', icon: 'fa fa-cubes', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/inventory/store-items', visible: this.authSvc.canViewInventory(), title: 'Tồn kho', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/inventory/stock-out', visible: this.authSvc.canViewDeliveryOrder(), title: 'Xuất kho', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/inventory/stock-in', visible: this.authSvc.canViewDeliveryOrder(), title: 'Nhập kho', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/inventory/stores', visible: this.authSvc.canManageStores(), title: 'Danh sách kho hãng', icon: '', class: '', isExternalLink: false, submenu: []
                    }
                ]
            },
            {
                path: '', visible: this.authSvc.canAccessAdministration(), title: 'Quản trị', icon: 'fa fa-cogs', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/administration/manage-service-groups', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý ngành hàng', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-product-groups', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý sản phẩm', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-models', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý Model', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-parts', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý linh kiện', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-items', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý số máy', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-symptoms', visible: this.authSvc.canAccessWarrantyAdministration(), title: 'Quản lý hiện tượng hư', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-solutions', visible: this.authSvc.canAccessWarrantyAdministration(), title: 'Quản lý cách sửa chữa', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-prices', visible: this.authSvc.canAccessWarrantyAdministration(), title: 'Quản lý phí sửa chữa', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-kpis', visible: this.authSvc.canAccessWarrantyAdministration(), title: 'Quản lý KPI', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-organizations', visible: this.authSvc.canAccessUserAdministration(), title: 'Quản lý trạm', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-users', visible: this.authSvc.canAccessUserAdministration(), title: 'Quản lý người dùng', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    // {
                    //     path: '/administration/general-settings', title: 'Cài đặt chung', icon: '', class: '', isExternalLink: false, submenu: []
                    // }
                ]
            }
        ];

        const menuItems = routes.filter(m => m.visible);
        menuItems.forEach(menuItem => {
            menuItem.submenu = menuItem.submenu.filter(m => m.visible);
        });

        return menuItems;
    }
}
