import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { MonthlyDateRange } from './monthly-date-range.model';

@Component({
    selector: 'app-monthly-date-range',
    templateUrl: './monthly-date-range.component.html',
    styleUrls: ['./monthly-date-range.component.scss']
})
export class MonthlyDateRangeComponent implements OnInit {
    monthItems = [];

    _months: number = 12;
    get months(): number {
        return this._months;
    }
    @Input('months')
    set months(value: number) {
        this._months = value;
        this.setMonths();
    }

    @Input() selectedMonth: MonthlyDateRange;
    @Output() selectedMonthChange = new EventEmitter<MonthlyDateRange>();
    @Output() change = new EventEmitter<MonthlyDateRange>();

    constructor() { }

    private setMonths() {
        for (let i = 0; i <= this._months; i++) {
            let mm = moment().startOf('month').subtract(i, 'months');
            this.monthItems.push({
                id: mm.toString(),
                text: mm.format('MM/YYYY'),
                fromDate: mm.toDate(),
                toDate: mm.endOf('month').toDate()
            });
        }
        //this.selectedMonth = this.monthItems[0];
    }

    ngOnInit() {
        this.setMonths();
    }

    onChange($event) {
        this.selectedMonthChange.emit($event);
        this.change.emit($event);
    }
}
