import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        data: { preload: true }
    },
    {
        path: 'warranty',
        loadChildren: './warranty/warranty.module#WarrantyModule',
        data: { preload: true }
    },
    {
        path: 'inventory',
        loadChildren: './inventory/inventory.module#InventoryModule',
        data: { preload: true }
    },
    {
        path: 'administration',
        loadChildren: './administration/administration.module#AdministrationModule',
        data: { preload: true }
    },
    {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule',
        data: { preload: true }
    },
    {
        path: 'my',
        loadChildren: './my/my.module#MyModule',
        data: { preload: true }
    }
];