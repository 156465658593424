import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../rest-api/rest-api.service';
import { TenantService } from './tenants.service';

@Injectable()
export class AuthService {
    token: string;
    tenant: string; // later should read from cookies
    userInfo: any;

    constructor(private rest: RestApiService, private tenantSvc: TenantService) {
        this.rest = rest;
        this.token = localStorage.getItem('token');
        this.tenant = this.tenantSvc.getCurrent().id;
        rest.configTenant(this.tenant);
    }

    login(username: string, password: string): Observable<{}> {
        return new Observable<{}>(observer => {
            return this.rest.post(`auth/login`, {
                userName: username,
                pwd: password
            }).subscribe(
                (result: any) => {
                    this.token = result.token;
                    this.rest.configToken(this.token);
                    localStorage.setItem('token', this.token);

                    observer.next(this.token);
                    observer.complete();
                },
                err => {
                    this.token = null;
                    observer.error(err);
                }
            );
        });
    }

    resetPassword(siteUrl: string, email: string): Observable<{}> {
        return this.rest.post('auth/reset-password', { link: siteUrl, email: email });
    }

    resetPasswordFromLink(linkId: string, password: string) {
        return this.rest.post('auth/reset-password-from-link', { loginLinkId: linkId, pwd: password });
    }

    getResetPasswordLink(id: string) {
        return this.rest.get(`auth/reset-password-link/${id}`);
    }

    logout() {
        this.token = null;
        this.userInfo = null;
        localStorage.removeItem('token');
        this.rest.configToken(this.token);
    }

    getToken() {
        return this.token;
    }

    getUser() {
        if (!this.userInfo) {
            this.userInfo = this.parseJwt(this.token);
        }
        return this.userInfo;
    }

    isAuthenticated(): boolean {
        if (!this.token) return false;

        const tokenInfo = this.parseJwt(this.token);
        if (tokenInfo.exp < Date.now() / 1000) {
            return false;
        }

        this.rest.configToken(this.token);
        return true;
    }

    hasRole(role: string): boolean {
        const user = this.getUser();
        return user && user.roles.indexOf(role) > -1;
    }

    hasAnyRole(roles: string[]): boolean {
        const user = this.getUser();
        return roles.filter(r => user && user.roles.indexOf(r) > -1).length > 0;
    }

    canAccessAdministration() {
        return this.canAccessManufacturingAdministration() 
            || this.canAccessUserAdministration()
            || this.canAccessWarrantyAdministration();
    }

    canAccessManufacturingAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_manufacturing'
            ])
        );
    }

    canAccessWarrantyAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_warranty'
            ])
        );
    }

    canAccessUserAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_user'
            ])
        );
    }

    isASP() {
        return !!this.getUser().organizationId;
    }

    isTech() {
        return this.isASP() && this.hasAnyRole([
            'asp_technicant'
        ]);
    }

    isASPManager() {
        return this.isASP() && this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canCreateServiceOrder() {
        if (this.isASP()) {
            return this.hasAnyRole([
                'asp_manager',
                'asp_warranty_create'
            ]);
        }

        return this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_create'
        ]);
    }

    canEditServiceOrder() {
        if (this.isASP()) {
            return this.hasAnyRole([
                'asp_manager',
                'asp_technicant'
            ]);
        }

        return this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_edit'
        ]);
    }

    canDeleteServiceOrder() {
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_delete'
        ]);
    }

    canApproveServiceOrder() {
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_approve'
        ]);
    }

    canPay() {
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_pay'
        ]);
    }

    canConfirmPartReturn() {
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_warranty_confirm_return'
        ]);
    }

    canViewPartReturn() {
        return this.isASP() && this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canExplain() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_warranty_approve'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canViewServiceOrder() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_warranty_view',
                'svc_warranty_create',
                'svc_warranty_approve',
                'svc_warranty_confirm_return'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager', 
            'asp_technicant'
        ]);
    }

    canViewReport() {
        return this.canViewServiceReport() 
            || this.canViewInventoryReport()
            || this.canViewServicePartReport();
    }

    canViewServiceReport() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_report_service'
            ]);
        }

        return true;

        // return this.hasAnyRole([
        //     'asp_manager'
        // ]);
    }

    canViewServicePartReport() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_report_part_service'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canViewInventoryReport() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_report_inventory'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canAccessStore(store) {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_inventory_deliver_in',
                'svc_inventory_deliver_out'
            ]);
        }
        return store.organizationId === this.getUser().organizationId && this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canCreateDeliveryOrder() {
        // if (this.isASP()){
        //     return this.isASPManager();
        // }
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_inventory_deliver_draft'
        ]);
    }

    canDeliver() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_inventory_deliver_out'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canDeleteConfirmedDeliveryOrder() {
        return this.hasAnyRole([
            'svc_administration_all'
        ]);
    }

    canReceive() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_inventory_deliver_in'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canViewDeliveryOrder() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_inventory_deliver_view',
                'svc_inventory_deliver_in',
                'svc_inventory_deliver_out'
            ]);
        }

        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canManageStores() {
        return !this.isASP() && this.hasAnyRole([
            'svc_administration_all',
            'svc_inventory_stores'
        ]);
    }

    canViewInventory() {
        if (!this.isASP()) {
            return this.hasAnyRole([
                'svc_administration_all',
                'svc_inventory',
                'svc_inventory_deliver_in',
                'svc_inventory_deliver_out'
            ]);
        }
        return this.hasAnyRole([
            'asp_manager'
        ]);
    }

    canApprovePartOrders() {
        return this.hasAnyRole([
            'svc_administration_all',
            'svc_inventory_part_order_approve'
        ]);
    }

    canDeleteJournal() {
        return this.hasAnyRole([
            'svc_inventory_delete_journal'
        ]);
    }

    getOrganizationId() {
        return this.getUser().organizationId;
    }

    private parseJwt(token: string) {
        function b64DecodeUnicode(str) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(atob(str).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }

        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(b64DecodeUnicode(base64));
    }
}
