export const serviceStatuses = [
    { id: 'open', text: 'Khởi tạo' },
    { id: 'assigned-asp', text: 'Chuyển trạm' },
    { id: 'assigned-tech', text: 'Chuyển kỹ thuật' },
    { id: 'received-tech', text: 'Kỹ thuật đã nhận' },
    { id: 'in-progress', text: 'Đang xử lý' },
    { id: 'part-order-waiting', text: 'Chờ đặt LK' },
    { id: 'part-order-approved', text: 'Đã duyệt cấp LK' },
    { id: 'part-order-delivered', text: 'Đã xuất LK' },
    { id: 'part-order-received', text: 'Đã nhận LK' },
    { id: 'finish-processing', text: 'Kỹ thuật hoàn tất' },
    { id: 'complete', text: 'Hoàn tất ca' },
    { id: 'request-information', text: 'Yêu cầu giải trình' },
    { id: 'request-solved', text: 'Đã giải trình' },
    { id: 'approved-payment', text: 'Duyệt thanh toán' },
    { id: 'rejected-payment', text: 'Không duyệt thanh toán' },
    { id: 'bill-sent', text: 'Đã xuất hóa đơn' },
    { id: 'bill-paid', text: 'Đã thanh toán' },
    { id: 'payment-cancelled', text: 'Hủy thanh toán' },
];

export const inProgressStatuses = ['received-tech', 'in-progress', 'part-order-waiting', 'part-order-approved', 'part-order-delivered', 'part-order-received', 'finish-processing'];
export const pendingStatuses = ['open', 'assigned-asp', 'assigned-tech'];
export const lockEditStatuses = ['complete', 'request-information', 'request-solved', 'approved-payment', 'rejected-payment', 'bill-sent', 'bill-paid', 'payment-cancelled'];
export const closedStatuses = ['bill-paid'];
export const readyForApprove = ['complete', 'request-solved'];
export const readyForPay = ['bill-sent'];
export const readyForReturnParts = ['complete', 'request-information', 'request-solved', 'approved-payment', 'rejected-payment', 'bill-sent', 'bill-paid', 'payment-cancelled'];

let all: any = {};
serviceStatuses.forEach(element => {
    all[element.id] = element;
});

export const serviceStatusesMaps: any = all;

