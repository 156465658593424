import { Injectable } from '@angular/core';

export const tenants = [
    {
        id: 'sharp',
        domainNames: ['sharp.akino.net.vn'],
        displayName: 'Trung tâm bảo hành BKNet',
        address: '638 Phạm Hùng, Hoà Phước, Hòa Vang, Đà Nẵng',
        phoneNos: ''
    },
    {
        id: 'pna',
        domainNames: ['pna.akino.net.vn', 'qlbh.akino.net.vn', 'khbh.akino.net.vn', 'localhost:4200','qlbh.akino.vn', 'khbh.akino.vn', 'pna-api.akino.vn', 'pna.akino.vn'],
        displayName: 'Trung tâm bảo hành Akino',
        address: '51 Nguyễn Văn Linh, P.Tân Thuận Tây, Q.7, HCM',
        phoneNos: '18006327'
    },
    {
        id: 'phanduy',
        domainNames: ['phanduy.akino.net.vn', 'phandung.akino.net.vn'],
        displayName: 'CÔNG TY TNHH MỘT THÀNH VIÊN PHAN DŨNG',
        address: '1210 Quang Trung,  Phường 8, Q.Gò Vấp HCM',
        phoneNos: '028.62 955 839, 0903 871 230'
    }
];

@Injectable()
export class TenantService {
    getCurrent() {
        let domainName = location.hostname.toLowerCase();
        if (location.port) {
            domainName += `:${location.port}`;
        }

        const tenant = tenants.find(t => {
            return t.domainNames.indexOf(domainName) >= 0;
        });
        return tenant;
    }
}