import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.isAuthenticated();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class ManufacturingAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessManufacturingAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class WarrantyAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessWarrantyAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class UserAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessUserAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class InventoryGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewInventory();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class ApprovePartOrdersGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canApprovePartOrders();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class StoresGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canManageStores();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class NewDeliverInGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canCreateDeliveryOrder();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class DeliverInGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canReceive();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class DeliverOutGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canDeliver();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class DeliverGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewDeliveryOrder();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class ServiceReportGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewServiceReport();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class ServicePartReportGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewServicePartReport();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class InventoryReportGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewInventoryReport();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}