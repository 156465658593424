import { Injectable } from '@angular/core';
import { ICounterService } from './ICounterService';
import { Observable } from 'rxjs';
import { RestApiService } from '../rest-api/rest-api.service';

@Injectable({
    providedIn: 'root'
})
export class StockCounterService implements ICounterService {
    constructor(private rest: RestApiService) { }

    load(option: any): Observable<number> {
        return new Observable<any>(subsriber => {
            this.rest.post('stock-orders/count', {
                    filter: option
                })
                .subscribe((result: any) => {
                    subsriber.next(result.count);
                },
                    error => subsriber.error(error)
                )
                .add(() => subsriber.complete());
        });
    }
}
