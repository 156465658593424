import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { LoaderComponent } from './miscs/loader/loader.component';
import { SwsGridComponent } from './sws-grid/sws-grid.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { CounterComponent } from './miscs/counter/counter.component';
import { MonthlyDateRangeComponent } from './miscs/monthly-date-range/monthly-date-range.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        LoaderComponent,
        SwsGridComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        ConfirmModalComponent,
        FileUploadModule,
        MonthlyDateRangeComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        NgxDatatableModule,
        FileUploadModule,
        NgSelectModule,
        FormsModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        LoaderComponent,
        SwsGridComponent,
        ConfirmModalComponent,
        CounterComponent,
        MonthlyDateRangeComponent
    ],
    entryComponents: [
        ConfirmModalComponent
    ]
})
export class SharedModule { }
